import React from 'react'
import { connect } from 'react-redux'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PrivacyPolicy from '../components/PrivacyPolicy'
import CookieNotice from '../components/shared/CookieNotice'

const PrivacyPolicyPage = ({ declarePageAsNotLanding }) => {
  declarePageAsNotLanding()
  return (
    <Layout>
      <SEO title='Privacy Policy' />
      <CookieNotice />
      <h1>Privacy Policy | Game-changing esports analytics - Shadow</h1>
      <PrivacyPolicy />
    </Layout>
  )
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    declarePageAsNotLanding: () => dispatch({ type: `SET_LANDING_PAGE_FALSE` }),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivacyPolicyPage)
